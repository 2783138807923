import * as styles from './dynamic-examples.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Button from '../button';

const EXAMPLES = [
  {
    industry: 'Coaches',
    brands: [
      {
        id: 'jennakutcher',
        name: 'Jenna Kutcher',
        description: 'Business',
        color: '#db6a34',
        quizTitle: "What's your secret sauce?",
        quizUrl: 'https://jennakutcher.com/secretsauce',
      },
      {
        id: 'amyporterfield',
        name: 'Amy Porterfield',
        description: 'Digital Marketing Coach',
        color: '#80adb3',
        quizTitle: 'Replace Your Income With A Digital Course',
        quizUrl: 'https://freebie.amyporterfield.com/quiz',
      },
      {
        id: 'toddlerscanread',
        name: 'Toddlers Can Read',
        description: 'Education',
        color: '#4b8471',
        quizTitle: 'How to Help Your Child Become a Better Reader',
        quizUrl: 'https://www.toddlersread.com/pages/advanced-reading-quiz',
      },
      {
        id: 'kayeputnam',
        name: 'Kaye Putnam',
        description: 'Brand Strategist',
        color: '#160a3b',
        quizTitle: 'Brand Personality Quiz',
        quizUrl: 'https://www.kayeputnam.com/brandality-quiz/',
      },
    ],
  },
  {
    industry: 'Creators',
    brands: [
      {
        id: 'advicewitherin',
        name: 'Advice With Erin',
        description: 'Career',
        color: '#f1401d',
        quizTitle: 'The Career Type Quiz',
        quizUrl: 'https://advicewitherin.com/quiz',
      },
      {
        id: 'mrsdowjones',
        name: 'Mrs. Dow Jones',
        description: 'Finance',
        color: '#2954f6',
        quizTitle: 'Free Customized Money Plan',
        quizUrl: 'https://www.mrsdowjones.com/quiz/financial-goals',
      },
      {
        id: 'salarytransparentstreet',
        name: 'Salary Transparent Street',
        description: 'Career',
        color: '#188c4a',
        quizTitle: 'Are You Underpaid?',
        quizUrl: 'https://www.salarytransparentstreet.com/resource-hub/are-you-underpaid-free-quiz',
      },
      {
        id: 'gretchenrubin',
        name: 'Gretchen Rubin',
        description: 'Author',
        color: '#00529d',
        quizTitle: 'Quiz: Habits for Happiness',
        quizUrl: 'https://gretchenrubin.com/quiz/',
      },
    ],
  },
  {
    industry: 'Service Providers',
    brands: [
      {
        id: 'elizabethmccravy',
        name: 'Elizabeth McCravy',
        description: 'Website Designer',
        color: '#fe6e97',
        quizTitle: 'Website Personality Quiz',
        quizUrl: 'https://elizabethmccravy.com/website-personality-quiz',
      },
      {
        id: 'thesororitynutritionist',
        name: 'The Sorority Nutritionist',
        description: 'Dietitian',
        color: '#383638',
        quizTitle: 'Why is your weight loss a hot mess?!',
        quizUrl: 'https://thesororitynutritionist.com/weight-loss-quiz',
      },
      {
        id: 'hiestates',
        name: 'HiEstates',
        description: 'Real Estate',
        color: '#082246',
        quizTitle: 'Is Living In Hawaii Right For You?',
        quizUrl: 'https://www.hiestates.com/learn/',
      },
      {
        id: 'jessicabaum',
        name: 'Jessica Baum',
        description: 'Relationship Coach',
        color: '#61769b',
        imageIndex: 5,
        quizTitle: "Let's Find Out Your Attachment Style.",
        quizUrl: 'https://www.beselffull.com/attachment-style-quiz',
      },
    ],
  },
  {
    industry: 'E-Commerce',
    description: 'Send personalized product offerings to your audience',
    brands: [
      {
        id: 'henryshouseofcoffee',
        name: "Henry's House of Coffee",
        description: 'Coffee Company',
        color: '#9d1c30',
        imageIndex: 6,
        quizTitle: 'Coffee Matchmaker',
        quizUrl: 'https://henryshouseofcoffee.com/pages/coffee-quiz',
      },
      {
        id: 'commonera',
        name: 'Common Era',
        description: 'Jewelry Brand',
        color: '#837067',
        imageIndex: 7,
        quizTitle: 'Which Ancient Goddess are you?',
        quizUrl: 'https://commonera.com/pages/goddess-quiz',
      },
      {
        id: 'naturalstacks',
        name: 'Natural Stacks',
        description: 'Brain Supplements',
        color: '#011e60',
        quizTitle: 'What is your neurotransmitter personality type?',
        quizUrl: 'https://www.naturalstacks.com/pages/neuro-personality-quiz',
      },
      {
        id: 'solcleanse',
        name: 'Sol Cleanse',
        description: 'Organic Juice Cleanses',
        color: '#61654c',
        imageIndex: 8,
        quizTitle: 'Which Cleanse is right for you?',
        quizUrl: 'https://solcleanse.com/pages/cleanse-quiz',
      },
    ],
  },
  {
    industry: 'Enterprise',
    description: 'Send personalized media offerings to your audience',
    brands: [
      {
        id: 'directenergy',
        name: 'Direct Energy',
        description: 'Energy',
        color: '#f36f24',
        quizTitle: 'What type of energy user are you?',
        quizUrl: 'https://www.directenergy.ca/en/learn/quiz-hub',
      },
      {
        id: 'atlassian',
        name: 'Atlassian',
        description: 'Productivity Software',
        color: '#0052cc',
        quizTitle: 'Does your team have a toxic workplace culture?',
        quizUrl: 'https://www.atlassian.com/blog/quizzes',
      },
      {
        id: 'twilio',
        name: 'Twilio',
        description: 'Communication Software',
        color: '#f22f46',
        quizTitle: 'Customer Tolerance Quiz',
        quizUrl: 'https://www.twilio.com/en-us/resource-center/customer-tolerance-quiz',
      },
      {
        id: 'bestfriends',
        name: 'Best Friends',
        description: 'Animal Non-Profit',
        color: '#ec720b',
        quizTitle: 'What is your animal soulmate?',
        quizUrl: 'https://bestfriends.org/e/animal-soulmate-quiz',
      },
    ],
  },
  {
    industry: 'Education',
    description: 'Send personalized course offerings to your audience',
    brands: [
      {
        id: 'nasa',
        name: 'NASA',
        description: 'Space Agency',
        color: '#000000',
        quizTitle: 'Find Your Artemis Mission',
        quizUrl: 'https://quiz.tryinteract.com/#/61a63be75a44ef001871beda',
      },
      {
        id: 'harvard',
        name: 'Harvard Medical School',
        description: 'Medicine',
        color: '#a61d31',
        quizTitle: 'HMX Immunology Quiz',
        quizUrl: 'https://onlinelearning.hms.harvard.edu/hmx/immunology-quiz/',
      },
      {
        id: 'purdue',
        name: 'Purdue University',
        description: 'Higher Education',
        color: '#c2a275',
        imageIndex: 10,
        quizTitle: 'Find-Your-Fit Quiz',
        quizUrl: 'https://lifeat.purdue.edu/find-your-fit-quiz/',
      },
      {
        id: 'nhm',
        name: 'Natural History Museum',
        description: 'Museum',
        color: '#000000',
        quizTitle: 'What Dinosaur Are You?',
        quizUrl: 'https://www.nhm.ac.uk/discover/what-dinosaur-are-you.html',
      },
    ],
  },
];

const DynamicExamples = () => {
  const data = useStaticQuery(graphql`
    query {
      exampleImages: allFile(filter: { relativePath: { regex: "/examples/" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 100, placeholder: BLURRED)
            }
            name
          }
        }
      }
    }
  `);

  const [currentExample, setCurrentExample] = useState(EXAMPLES[0]);
  const brands = currentExample.brands.map((brand) => ({
    ...brand,
    image: data.exampleImages.edges.find((edge) => edge.node.name === brand.id)?.node
      .childImageSharp.gatsbyImageData,
  }));

  function handleSelectIndustry(example) {
    setCurrentExample(example);
  }

  return (
    <>
      <div className="row">
        <div className={styles.industryCards}>
          {EXAMPLES.map(({ industry }, index) => (
            <button
              key={index}
              className={[
                styles.industryBtn,
                currentExample?.industry === industry ? styles.active : '',
              ].join(' ')}
              onClick={() => handleSelectIndustry(EXAMPLES[index])}>
              {industry}
            </button>
          ))}
        </div>
      </div>
      <div className="row">
        {brands.map((brand, index) => (
          <div
            className="col-md-6 col-lg-3 col-xl-3"
            key={`${currentExample.industry}-${index}`}
            style={{ '--brand-color': brand.color }}>
            <div className={styles.customerCard}>
              <GatsbyImage image={getImage(brand.image)} alt={brand.name} />
              <div className={styles.customerCardBackground}></div>
              <div className={styles.customerCardSolidBackground}></div>
              <div className={styles.customerCardContent}>
                <p className="heading-lg color-white">{brand.name}</p>
                <p className="heading-sm color-white">{brand.description}</p>
                <Button
                  theme="white"
                  href={brand.quizUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'var(--brand-color)' }}>
                  Take Quiz
                </Button>
              </div>
              <div className={styles.customerCardOverlay}>
                <p className="heading-lg color-white">{brand.quizTitle}</p>
                <Button
                  theme="white"
                  href={brand.quizUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'var(--brand-color)' }}>
                  Take Quiz
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row justify-content-center">
        <div className="col-auto">
          <Button
            theme="outline"
            size="large"
            type="internal"
            href="/examples"
            style={{ marginTop: '2rem' }}>
            See more examples
          </Button>
        </div>
      </div>
    </>
  );
};

export default DynamicExamples;
