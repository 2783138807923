import * as React from 'react';
import * as styles from './index.module.scss';

import Brand from '../components/brand';
import Button from '../components/button';
import Callout from '../components/v2/callout';
import DynamicExamples from '../components/index/dynamic-examples';
import EmbedButton from '../components/embed-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import SEO from '../components/seo';
import Section from '../components/section';
import { StaticImage } from 'gatsby-plugin-image';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

const IndexPage = ({ data }) => {
  return (
    <>
      <SEO
        title="Make Quizzes For Your Business"
        description="Create quizzes to engage your audience, generate leads, and recommend your products. Provide value to your customers and insights for your team."
      />
      <Header
        btnLabel="Book a demo"
        btnLink="/demo/"
        btnTheme="outline"
        btnType="internal"
        btnIcon={false}
      />
      <main className={styles.index}>
        <Section theme="white" style={{ padding: `5rem 0`, marginTop: `5rem` }}>
          <div className="row align-items-center">
            <div className="col-lg-7">
              <h1 className="color-black" style={{ marginBottom: `2.5rem` }}>
                Connect with your customers <span>at scale</span>
              </h1>
              <h2 className="text-body color-muted-foreground">
                Build interactive experiences that drive engagement, generate leads, and gather
                insights.
              </h2>
              <div className={styles.buttons}>
                <Button
                  theme="primary"
                  size="large"
                  type="external"
                  href="https://dashboard.tryinteract.com/register"
                  style={{ gap: '12px' }}>
                  Start for free <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
              <p className={styles.subtext}>No credit card required</p>
            </div>
            <div className="col-lg-5">
              <div className={styles.customerCards}>
                <div
                  className={[styles.customerCard, 'order-0 order-md-4'].join(' ')}
                  style={{ '--brand-color': '#000000' }}>
                  <StaticImage src="../images/examples/nasa.png" alt="NASA" placeholder="blurred" />
                  <div className={styles.customerCardBackground}></div>
                  <div className={styles.customerCardSolidBackground}></div>
                  <div className={styles.customerCardContent}>
                    <p>NASA</p>
                    <p>Space Agency</p>
                    <Button
                      theme="white"
                      href="https://www.digitalgardengirl.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                  <div className={styles.customerCardOverlay}>
                    <p>Find Your Artemis Mission</p>
                    <Button
                      theme="white"
                      size="small"
                      href="https://quiz.tryinteract.com/#/61a63be75a44ef001871beda"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                </div>
                <div className={styles.customerCard} style={{ '--brand-color': '#f1401d' }}>
                  <StaticImage
                    src="../images/examples/advicewitherin.png"
                    alt="Advice With Erin"
                    placeholder="blurred"
                  />
                  <div className={styles.customerCardBackground}></div>
                  <div className={styles.customerCardSolidBackground}></div>
                  <div className={styles.customerCardContent}>
                    <p>Advice With Erin</p>
                    <p>Creator</p>
                    <Button
                      theme="white"
                      href="https://advicewitherin.com/quiz"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                  <div className={styles.customerCardOverlay}>
                    <p>The Career Type Quiz</p>
                    <Button
                      theme="white"
                      size="small"
                      href="https://advicewitherin.com/quiz"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                </div>
                <div className={styles.customerCard} style={{ '--brand-color': '#858791' }}>
                  <StaticImage
                    src="../images/examples/henryshouseofcoffee.png"
                    alt="Henry's House of Coffee"
                    placeholder="blurred"
                  />
                  <div className={styles.customerCardBackground}></div>
                  <div className={styles.customerCardSolidBackground}></div>
                  <div className={styles.customerCardContent}>
                    <p>Henry's House of Coffee</p>
                    <p>E-Commerce</p>
                    <Button
                      theme="white"
                      href="https://henryshouseofcoffee.com/pages/coffee-quiz"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                  <div className={styles.customerCardOverlay}>
                    <p>Coffee Matchmaker</p>
                    <Button
                      theme="white"
                      size="small"
                      href="https://henryshouseofcoffee.com/pages/coffee-quiz"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                </div>
                <div className={styles.customerCard} style={{ '--brand-color': '#4b8471' }}>
                  <StaticImage
                    src="../images/examples/toddlerscanread.png"
                    alt="Toddler Can Read"
                    placeholder="blurred"
                  />
                  <div className={styles.customerCardBackground}></div>
                  <div className={styles.customerCardSolidBackground}></div>
                  <div className={styles.customerCardContent}>
                    <p>Toddlers Can Read</p>
                    <p>Education</p>
                    <Button
                      theme="white"
                      href="https://www.toddlersread.com/pages/advanced-reading-quiz"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                  <div className={styles.customerCardOverlay}>
                    <p>How to Help Your Child Become a Better Reader</p>
                    <Button
                      theme="white"
                      size="small"
                      href="https://www.toddlersread.com/pages/advanced-reading-quiz"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h3 className="heading-xl color-black">
                Explore businesses using quizzes in your industry
              </h3>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <DynamicExamples />
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="row">
                <div className="col">
                  <p className="text-body color-black text-center">Trusted by leading businesses</p>
                </div>
              </div>
              <div className="row align-items-center justify-content-center justify-content-lg-between">
                <Brand id="gretchen-rubin" className={`col-auto ${styles.brand}`} />
                <Brand id="jennakutcher-color" className={`col-auto ${styles.brand}`} />
                <Brand id="melyssagriffin-color" className={`col-auto ${styles.brand}`} />
                <Brand id="redcross-color" className={`col-auto ${styles.brand}`} />
                <Brand id="atlassian" className={`col-auto ${styles.brand}`} />
                <Brand id="lush-color" className={`col-auto ${styles.brand}`} />
              </div>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row">
            <div className="col-12 text-center">
              <p className="heading-lg color-black" style={{ marginBottom: '1rem' }}>
                Empowering businesses since 2014 with proven results:
              </p>
            </div>
          </div>
          <div
            className={`row no-gutters align-items-center justify-content-center ${styles.metrics}`}>
            <div className="col-md-4">
              <p className={styles.number}>1.2B</p>
              <p className={styles.label}>quiz impressions</p>
            </div>
            <div className="col-md-4">
              <p className={styles.number}>400M</p>
              <p className={styles.label}>quizzes completed</p>
            </div>
            <div className="col-md-4">
              <p className={styles.number}>85M</p>
              <p className={styles.label}>leads generated</p>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div
            className="row no-gutters align-items-stretch justify-content-center"
            style={{ borderRadius: '32px', overflow: 'hidden', background: `#edf0ff` }}>
            <div className="col-lg-6 col-xl-5 order-lg-1 text-md-center">
              <div className={styles.quizContainer}>
                <p
                  className="heading-xs-upper color-purple-500"
                  style={{ margin: '0', fontWeight: '700', fontSize: '14px' }}>
                  Take Quiz
                </p>
                <p
                  className="heading-lg color-primary-950 font-style-golos"
                  style={{ margin: `0`, marginTop: '1rem', fontWeight: '700' }}>
                  What quiz should you make for your business?
                </p>
                <p className="heading-sm color-primary-950" style={{ margin: `1rem 0 2rem 0` }}>
                  Find your perfect lead generation quiz. Unlock the power of a 40% conversion rate
                  that quizzes offer by finding the exact quiz type that will work for your
                  business.
                </p>
                <EmbedButton id="65414c9de4c0cb0014c537e5" disableCover={true}>
                  Find out now
                </EmbedButton>
              </div>
            </div>
            <div className="col-md-8 col-lg-6 col-xl-7 order-lg-0 text-center">
              <StaticImage
                src="../images/landing-quiz.png"
                alt="Interact template screenshots"
                placeholder="blurred"
              />
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-black">What quizzes can do for your business</h3>
            </div>
          </div>
          <div
            className="row justify-content-center align-items-stretch"
            style={{ marginTop: '3rem', rowGap: '1.5rem' }}>
            <div className="col-lg-6">
              <div className={styles.feature}>
                <p className="heading-xs-upper">Lead magnet</p>
                <p className="heading-lg">Convert traffic into leads</p>
                <p className="heading-sm">
                  Engage your website visitors with a quiz that appeals to exactly what is on their
                  minds when they come to your site. Then convert your visitors into leads through
                  the quiz experience.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.feature}>
                <p className="heading-xs-upper">E-Commerce</p>
                <p className="heading-lg">Recommend products and services</p>
                <p className="heading-sm">
                  Personalize your product and service lineup for every potential customer and
                  recommend exactly the right offer for them based on their wants and needs.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.feature}>
                <p className="heading-xs-upper">Segmentation</p>
                <p className="heading-lg">Assess your customers and recommend a starting point</p>
                <p className="heading-sm">
                  Identify the needs of your customers based on an assessment so they can see
                  clearly how to begin working with your business.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.feature}>
                <p className="heading-xs-upper">Customer support</p>
                <p className="heading-lg">Answer customer questions</p>
                <p className="heading-sm">
                  Be there for every customer with a unique answer to their complex questions you
                  would otherwise have to have a human answer in real time, 24/7.
                </p>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-auto d-none d-sm-block">
              <div className={styles.integrations}>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Klaviyo"
                    style={{ backgroundPosition: '0px -920px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Zapier"
                    style={{ backgroundPosition: '0px -1760px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Mailchimp"
                    style={{ backgroundPosition: '0px -1000px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Interact"
                    style={{ backgroundPosition: '0px -800px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Kit"
                    style={{ backgroundPosition: '0px -280px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Flodesk"
                    style={{ backgroundPosition: '0px -520px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Constant Contact"
                    style={{ backgroundPosition: '0px -240px' }}></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-black">Integrate quizzes with your existing tools</h3>
            </div>
            <div className="col-lg-6 col-xl-6 text-center">
              <p className="heading-sm">
                Identify the needs of your customers based on an assessment so they can see clearly
                how to begin working with your business.
              </p>
              <Button
                theme="outline"
                size="large"
                type="internal"
                href="/integrations"
                style={{ marginTop: '2rem' }}>
                Browse integrations
              </Button>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-black">Our customers love Interact quizzes</p>
            </div>
          </div>
          <div className="row justify-content-center" style={{ marginTop: '3rem' }}>
            <div className="col-12 col-lg-10 col-xl-8">
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  Well, since my own email list is growing by 5,000 subscribers each and every week,
                  I can confidently say that having a fun quiz plays a big role in generating these
                  kind of numbers.
                  <br />
                  <br />
                  My quiz has also been one of the biggest contributors in increasing my unpaid
                  email list growth, which I love!
                </p>
                <div className={styles.caption}>
                  <StaticImage
                    src="../images/testimonials/testimonials-9.jpg"
                    alt="Jenna Kutcher"
                    placeholder="blurred"
                    width={150}
                    height={150}
                    style={{
                      borderRadius: `50%`,
                      width: '72px',
                      borderRadius: `50%`,
                      transform: `translateZ(0)`,
                    }}
                  />
                  <p className="text-body color-black" style={{ marginTop: '0.5rem' }}>
                    Jenna Kutcher
                  </p>
                  <p className="text-body">NYT Best-Selling Author</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-10 col-xl-8">
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  On the custom website design side of my business, I created an Interact quiz
                  almost two years ago for one of my previous marketing clients. That quiz has
                  generated over 10,000 new email subscribers in the past two years! Their quiz has
                  a 58% percent completion rate.
                  <br />
                  <br />
                  Plus, the subscribers who come from their quiz are always very engaged and tend to
                  open emails at a higher rate than other subscribers. The same is true for my own
                  business.
                </p>
                <div className={styles.caption}>
                  <StaticImage
                    src="../images/testimonials/testimonials-8.jpg"
                    alt="Elizabeth McCravy"
                    placeholder="blurred"
                    width={150}
                    height={150}
                    style={{
                      borderRadius: `50%`,
                      width: '72px',
                      borderRadius: `50%`,
                      transform: `translateZ(0)`,
                    }}
                  />
                  <p className="text-body color-black" style={{ marginTop: '0.5rem' }}>
                    Elizabeth McCravy
                  </p>
                  <p className="text-body">ShowIt Website Designer</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-10 col-xl-8">
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  Quizzes are an amazing way to convert people. You get to know so much about an
                  individual person, or even about your people, and the trends that exist within
                  your audience.
                </p>
                <div className={styles.caption}>
                  <StaticImage
                    src="../images/testimonials/testimonials-7.jpg"
                    alt="Minessa Konecky"
                    placeholder="blurred"
                    width={150}
                    height={150}
                    style={{
                      borderRadius: `50%`,
                      width: '72px',
                      borderRadius: `50%`,
                      transform: `translateZ(0)`,
                    }}
                  />
                  <p className="text-body color-black" style={{ marginTop: '0.5rem' }}>
                    Minessa Konecky
                  </p>
                  <p className="text-body">CEO, Research Administration Expert</p>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Callout />
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
